@import '../variables.module.scss';
@import '../mixins.scss';

.logo {
  max-width: 100%;
  max-height: 100%;
}

.logo-container {
  position: relative;
  height: 40px;
  width: 100px;

  @include large {
    width: 126px;
    height: 50px;
  }

  & > svg > circle {
    // transition: all 0.5s ease;
    transform-origin: center;
    will-change: transform;
  }

  &--loading {
    & > svg > circle {
      animation: rotating 1s linear infinite;
    }
  }

  &--show {
    opacity: 1;
  }
}

@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.logo-outer-wrapper {
  position: relative;

  &__circle {
    position: absolute;
    width: 21px;
    height: 21px;
    left: 42px;
    top: 0px;

    @include large {
      width: 28px;
      height: 28px;
      left: 51px;
      top: -1px;
    }
  }

  // &--colored {
  //   height: 35px;

  //   @include large {
  //     width: 130px;
  //     height: 52px;
  //   }
  // }
}