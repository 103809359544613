@import '../../styles/variables.module.scss';

.footer {
  background: $color-black;
  padding: gap(3) 0;
  position: relative;
  z-index: $z-index-footer;

  @include medium {
    padding: gap(3);
  }

  * {
    // change font-color to inherit,
    // which is the current page color
    color: inherit;
    font-size: $font-size-xs;
  }

  a {
    transition: color $ease-link;
    text-decoration: underline;

    &:hover {
      color: $color-white;
    }
  }

  // if page color is very light (or white),
  // use a different color for hover
  &--dark-theme {
    a:hover {
      color: $color-pink;
    }
  }

  // extend background on left & right side
  &:before,
  &:after {
    content: '';
    display: block;
    background: $color-black;
    position: absolute;
    height: 100%;
    max-height: 100%;
    width: 20px;
    top: 0;
  }

  &:before {
    left: -20px;
    border-radius: $border-radius-sm 0 0 0;
  }

  &:after {
    right: -20px;
    border-radius: 0 $border-radius-sm 0 0;
  }

  ul {
    margin-bottom: gap(2);
  }

  &--inverted {
    border-top: 1px solid $color-white;

    &:before,
    &:after {
      top: -1px;
    }

    &:before {
      border-top: 1px solid $color-white;
      border-left: 1px solid $color-white;
    }

    &:after {
      border-top: 1px solid $color-white;
      border-right: 1px solid $color-white;
    }

    & > * {
      color: $color-white;
    }

    a {
      color: $color-white !important;
      transition: opacity 0.3s ease;
      opacity: 1;

      &:hover {
        opacity: 0.6;
      }
    }

    svg,
    svg g,
    svg path {
      fill: $color-white;
      transition: opacity 0.3s ease;
      opacity: 1;
    }

    svg {
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
