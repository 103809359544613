@import './variables.module.scss';
@import './colors.scss';
@import './typography.scss';
@import './spacing.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family-regular;
  overflow-x: hidden;
  max-width: 100vw;
  overscroll-behavior: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

image,
video {
  max-width: 100%;
}

img {
  transition: 0.4s;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1800px !important;
  margin: 0 auto;
}

.reverse-content {
  flex-direction: row-reverse;
}

.container-fluid {
  padding: 0 gap(2);

  @include medium {
    padding: 0 gap(3);
  }
}

.underline {
  text-decoration: underline;
}

.main-wrapper {
  &--invert-colors {
    p,
    li,
    strong,
    b,
    h1,
    h2,
    h3,
    h4,
    div {
      color: $color-white;
    }
    a {
      color: $color-pink;
    }
  }
}

textarea,
select,
input,
button {
  outline: none;
}

.full-width {
  width: 100%;
}

.link {
  text-decoration: underline;
}

.line-break {
  &--xs {
    display: block;

    @include x-small {
      display: none;
    }
  }

  &--sm {
    display: block;

    @include small {
      display: none;
    }
  }

  &--md {
    display: block;

    @include medium {
      display: none;
    }
  }

  &--lg {
    display: block;

    @include large {
      display: none;
    }
  }

  &--xl {
    display: block;

    @include x-large {
      display: none;
    }
  }
}

.flex-column {
  &-reverse {
    &--sm {
      flex-direction: column-reverse;

      @include medium {
        flex-direction: unset;
      }
    }
  }
}

.emphasize {
  z-index: $z-index-emphasize !important;
}

.prevent-line-break {
  white-space: nowrap;
}

.prevent-hyphenation {
  hyphens: none !important;
}

.hide {
  &--sm {
    display: none;

    @include medium {
      display: unset;
    }
  }
}

.show {
  &--sm {
    display: unset;

    @include medium {
      display: none;
    }
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
